import React from "react";
import dayjs from "dayjs";
import { Col, Form, Row, Select, theme, Space, Button, ConfigProvider } from "antd";
import { PlusCircleOutlined, FilePdfOutlined , FileExcelOutlined} from '@ant-design/icons';
import { DATETIME_FORMAT, EXPORT_STATUS, EXPORT_STATUS_OPTIONS, EXPORT_STATUS_TH, HUMAN_DATETIME_FORMAT, USER_GROUP } from "../constants/strings";
import ColorButton from "../components/common/ColorButton";
import { useNavigate } from "react-router-dom";
import TSTable from "../components/common/TSTable";
import moment from "moment";
import { PATH } from "../routes/CustomRoutes";
import { SECONDARY_COLOR } from "../constants/color";
import SIDetailModal from "../components/menuExport/SIDetailModal";
import { URL_WEIGHT, URL_PRODUCT } from "../constants/urls";
import { useUserContext } from "../hooks/UserSettingProvider";
import { currencyFormat } from "../frameworks/Util";
import useDropdownItem from "../hooks/useDropdownItem";


export default function ExportProductListView() {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [filterStatus, setFilterStatus] = React.useState(user.groups.find(group => group === USER_GROUP.SALE) ? null : "unfinished")
  const [openSI, setOpenSI] = React.useState(false);
  const [form] = Form.useForm(null);
  const tableRef = React.useRef(null);
  const MINUTE_MS = 60000;
  const [productOptions, fetcthProductOptions] = useDropdownItem({
    url: URL_PRODUCT.PRODUCT,
    manual: true, pagination: true,
    key: 'name', value: 'name', label: 'name'
  });

  const {
		token: { colorPrimary },
	} = theme.useToken();

  const columns = [
    {
      title: "วันที่ - เวลา",
      dataIndex: "created",
      render: (value) => moment(value).format(HUMAN_DATETIME_FORMAT),
      width: 150
    },
    {
      title: "หมายเลข SI",
      dataIndex: "code",
      searcher: true,
    },
    {
      title: "ทะเบียนรถ",
      dataIndex: "vehicle_license_plate",
      searcher: true,
    },
    {
      title: "ลูกค้า",
      dataIndex: "customer_name",
      searcher: true,
    },
    {
      title: "สินค้า",
      dataIndex: "product_name",
      searcher: true,
      searcherOptions: productOptions,
    },
    {
      title: "คุณภาพ (Grade)",
      dataIndex: "product_grade_name",
    },
    {
      title: "น้ำหนักประมาณ",
      dataIndex: "goal_weight",
      render: (value) => currencyFormat(value)
    },
    {
      title: "น้ำหนักสุทธิ",
      dataIndex: "snapshot_sum_product_weight",
      render: (value) => currencyFormat(value)
    },
    {
      title: "สถานะ",
      dataIndex: "status", 
      render : (value) => (value ? EXPORT_STATUS_TH[value] : "")
    }]

  const fetchData = () => {
    if (tableRef.current) {
      tableRef.current.fetch();
    }
  }

  React.useEffect(() => {
    fetchData();
  },[filterStatus])

  React.useEffect(() => {
    const interval = setInterval(() => {
      if(tableRef.current && tableRef.current.getCurrentPage() === 1) {
        fetchData();
      }
    }, MINUTE_MS);

    fetcthProductOptions()

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  return (
    <div>
      <Form
        form={form}
        autoComplete="off">
        <Space style={{ float: 'right' }}>
          <Row gutter={8}>
            <Col>
              <Button
                icon={<FilePdfOutlined />}
                onClick={async () => {
                  await tableRef.current.exportPDF({
                    title: "รายการชั่งขาย (ตาชั่งเล็ก)",
                    subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
                    landscape: true
                  })
                }}>
                PDF
              </Button>
            </Col>
            <Col>
              <Button
                icon={<FileExcelOutlined />}
                onClick={async () => {
                  await tableRef.current.exportXlsx({
                    title: "รายการชั่งขาย (ตาชั่งเล็ก)",
                    subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)}`,
                    filename: `รายการชั่งขาย (ตาชั่งเล็ก) - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
                  })
                }}>
                Excel
              </Button>
            </Col>
            <Col>
              {(user.groups.find(group => group === USER_GROUP.SALE) ? true : false) &&
                <ColorButton
                  type={"primary"}
                  override={SECONDARY_COLOR}
                  icon={<PlusCircleOutlined />}
                  style={{ float: "right", background: colorPrimary, marginBottom: 16 }}
                  onClick={() => setOpenSI(true)}>
                  เพิ่ม SI
                </ColorButton>}
            </Col>
          </Row>
        </Space>

        <Row gutter={[16, 16]} wrap>
          <Col lg={8} md={12} xs={24}>
              <Form.Item
                name={'filterStatus'}
                label={'สถานะ'}>
                <Select 
                  autoComplete="off" 
                  style={{ width : '100%'}} 
                  placeholder="ประเภท" 
                  onChange={value => setFilterStatus(value)}
                  value={filterStatus}
                  defaultValue={user.groups.find(group => group === USER_GROUP.SALE) ? "รายการทั้งหมด" : "อยู่ระหว่างดำเนินการ"}
                  options={EXPORT_STATUS_OPTIONS}
                  />
              </Form.Item>
          </Col>
        </Row>

      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: '#FF9933 !important' ,  // Table header background color
            },
          },
        }}
      >

      <TSTable
        ref={tableRef}
        columns={columns}
        rowKey={"id"}
        filterColor={'black'}
        url={`${URL_WEIGHT.SHIPPING_INSTRUCTION}?factory=${user.factory}`}
        size={"small"}
        pageSize={10}
        params={{ status: filterStatus ? filterStatus : null }}
        onRow={(record, rowIndex) => ({
          onClick: _ => navigate(`${PATH.EXPORT_PRODUCTS_SCALE}/${record.id}/`)
        })}
        rowClassName={(rowData) => {
          if (rowData.status === EXPORT_STATUS.CHECKING) {
            return 'table-row-red'
          }
          else if (rowData.status === EXPORT_STATUS.PENDING) {
            return 'table-row-yellow'
          }
        }}
      />
      </ConfigProvider>
      <SIDetailModal
        open={openSI}
        onClose={() => setOpenSI(false)}
        onUpdate={() => {
          setOpenSI(false)
          fetchData()
        }}
      />
    </Form>
    </div>
  )
}
