import React from 'react';
import { useUserContext } from '../hooks/UserSettingProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH } from '../routes/CustomRoutes';
import Sider from 'antd/es/layout/Sider';
import { Button, Drawer, Image, Layout, Menu, Popover, Space, theme } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, UnlockOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import logo from '../assets/lt.png';
import { PRIMARY_LIGHT_COLOR, BLACK_COLOR } from '../constants/color';
import { Content, Header } from 'antd/es/layout/layout';
import { useWindowDimensionContext } from '../hooks/WindowDimensionProvider';
import * as _ from 'lodash';
import ChangePasswordModal from '../components/user/ChangePasswordModal';
import LogoutModal from '../components/user/LogoutModal';
import SupplierListView from '../views/SupplierListView';
import TicketListView from '../views/TicketListView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faScaleBalanced, 
  faCashRegister, 
  faGears, 
  faCar, 
  faUserGear, 
  faTruckMoving, 
  faWarehouse, 
  faPallet, 
  faTruckRampBox, 
  faFile
} from '@fortawesome/free-solid-svg-icons'
import ImportProductListView from '../views/ImportProductListView';
import { USER_GROUP } from '../constants/strings';
import ExportProductListView from '../views/ExportProductListView';
import VehicleView from '../views/VehicleView';
import InventoryListView from '../views/InventoryListView';
import LoadProductListView from '../views/LoadProductListView';
import PalletListView from '../views/PalletListView';
import ReportsViews from '../views/ReportView';

export default function MenuScreen() {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('sm');
  // For menu
  const [currentMenu, setCurrentMenu] = React.useState(null);
  const [collapsed, setCollapsed] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [openUserMenu, setOpenUserMenu] = React.useState(false);
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const [openLogout, setOpenLogout] = React.useState(false);

  const {
    token: { colorBgContainer, colorPrimary, colorInfo },
  } = theme.useToken();

  const hasPermission = (target) => user.groups.find(group => group === target) ? true : false

  const menus = [
    /** Truck scale */
    { 
      key: PATH.TICKETS.substring(1), 
      icon: <FontAwesomeIcon icon={faScaleBalanced} />, 
      label: 'ตั๋วชั่ง',
      permissions: (hasPermission(USER_GROUP.TRUCK_SCALE)).toString(),
    },
    // { 
    //   key: PATH.SCALE_SETTINGS.substring(1), 
    //   icon: <FontAwesomeIcon icon={faGears} />, 
    //   label: 'ตั้งค่า',
    //   permissions: (hasPermission(USER_GROUP.TRUCK_SCALE) || hasPermission(USER_GROUP.PALLET_SCALE)).toString(),
    // },
    { 
      key: PATH.VEHICLE.substring(1), 
      icon: <FontAwesomeIcon icon={faCar} />, 
      label: 'ยานพาหนะ',
      permissions: (hasPermission(USER_GROUP.TRUCK_SCALE)).toString(),
    },
    /** Pallet scale */
    { 
      key: PATH.IMPORT_PRODUCTS.substring(1), 
      icon: <FontAwesomeIcon icon={faCashRegister} />, 
      label: 'ชั่งซื้อ',
      permissions: (hasPermission(USER_GROUP.PALLET_SCALE)).toString(),
    },
    { 
      key: PATH.EXPORT_PRODUCTS.substring(1), 
      icon: <FontAwesomeIcon icon={faTruckMoving} />, 
      label: 'ชั่งขาย',
      permissions: (hasPermission(USER_GROUP.PALLET_SCALE) || hasPermission(USER_GROUP.SALE)).toString(),
    },
    /** Store */
    { 
      key: PATH.INVENTORY.substring(1), 
      icon: <FontAwesomeIcon icon={faWarehouse} />, 
      label: 'คลังสินค้า',
      permissions: (hasPermission(USER_GROUP.PALLET_SCALE) || hasPermission(USER_GROUP.SALE)).toString(),
    },
    { 
      key: PATH.PALLET_MANAGEMENT.substring(1), 
      icon: <FontAwesomeIcon icon={faPallet} />, 
      label: 'จัดการพาเลท',
      permissions: (hasPermission(USER_GROUP.PALLET_SCALE)).toString(),
    },
    /** Supplier */
    {
      key: PATH.SUPPLIERS.substring(1),
      icon: <FontAwesomeIcon icon={faUserGear} />,
      label: 'ลูกค้า',
      permissions: (hasPermission(USER_GROUP.SUPPLIER_MANAGER) || hasPermission(USER_GROUP.TRUCK_SCALE)).toString(),
    },
    /** Loader */
    { 
      key: PATH.LOAD_PRODUCT.substring(1), 
      icon: <FontAwesomeIcon icon={faTruckRampBox} />, 
      label: 'โหลดสินค้า',
      permissions: (hasPermission(USER_GROUP.PRODUCT_LOADER)).toString(),
    },
    /** Report */
    { 
      key: PATH.REPORT.substring(1), 
      icon: <FontAwesomeIcon icon={faFile} />, 
      label: 'รายงาน',
      permissions: (hasPermission(USER_GROUP.REPORTER)).toString(),
    },
  ] 

  const onMenuSelected = (e) => {
    if (e.key !== currentMenu) {
      setCurrentMenu(e.key);
      navigate(`/${e.key}`);
    }
  }

  const renderContent = () => {
    switch (currentMenu) {
      /** Truck scale */
      case PATH.TICKETS.substring(1): return <div><TicketListView/></div>
      case PATH.SCALE_SETTINGS.substring(1): return <div>SCALE_SETTINGS</div>
      case PATH.VEHICLE.substring(1): return <div><VehicleView/></div>
      /** Supplier */
      case PATH.SUPPLIERS.substring(1): return <SupplierListView readOnly={!hasPermission(USER_GROUP.SUPPLIER_MANAGER)}/>
      /** Pallet scale */
      case PATH.IMPORT_PRODUCTS.substring(1): return <div><ImportProductListView/></div>
      case PATH.EXPORT_PRODUCTS.substring(1): return <div><ExportProductListView/></div>
      /** Store */
      case PATH.INVENTORY.substring(1): return <div><InventoryListView/></div>
      case PATH.PALLET_MANAGEMENT.substring(1): return <div><PalletListView/></div>
      /** Loader */
      case PATH.LOAD_PRODUCT.substring(1): return <div><LoadProductListView/></div>
      /** Report */
      case PATH.REPORT.substring(1): return <ReportsViews />
    }
  }

  const getMenus = () => {
    return (
      <Menu
        // theme="dark"
        mode='inline'
        style={{backgroundColor: PRIMARY_LIGHT_COLOR, borderColor: PRIMARY_LIGHT_COLOR}}
        onClick={(e) => {
          onMenuSelected(e);
          setDrawerOpen(false);
        }} 
        defaultSelectedKeys={[currentMenu]}
        selectedKeys={[currentMenu]}
        items={menus.filter(menu => menu.permissions != "false")}>
      </Menu>
    )
  }

  const autoRoute = ({menu}) => {
    const targetMenu = menus.filter(m => m.permissions === "true").find(m => menu === m.key); 
    const firstMenu = menus.find(menu => menu.permissions === "true")
    if (targetMenu) {
      setCurrentMenu(targetMenu.key);
    } else {
      setCurrentMenu(firstMenu.key);
    }
  }
  
  React.useEffect(() => {
    autoRoute(params);
  }, [params])

  React.useEffect(() => {
    console.log(user)
  },[user])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {isOnMobile ? (
        <Drawer
          style={{ background: PRIMARY_LIGHT_COLOR}}
          placement='left'
          title=''
          headerStyle={{ height: 0 }}
          bodyStyle={{ padding: 0 }}
          width={250}
          closeIcon={false}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}>
          <div style={{textAlign: "center", marginTop: 24, marginBottom: 16}}>
            <Image height={98} src={logo} preview={false}/>
          </div>

          {getMenus()}
        </Drawer>
      ) : (
        <Sider 
          className='shadow'
          style={{ background: PRIMARY_LIGHT_COLOR}}
          trigger={null} collapsible collapsed={collapsed}>
          <div style={{textAlign: "center", marginTop: 24, marginBottom: 16}}>
            <Image height={collapsed ? 48 : 98} src={logo} preview={false}/>
          </div>

          {getMenus()}
        </Sider>
      )}
      
      <Layout style={{ }}>
        <Header style={{ padding: 0, background: PRIMARY_LIGHT_COLOR, color: BLACK_COLOR, fontWeight: "bold" }}>
          <Button
            style={{ color: BLACK_COLOR, fontSize: '16px', width: 64, height: 64 }}
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => {
              if (isOnMobile) 
                setDrawerOpen(true)
              else
                setCollapsed(!collapsed)
            }}/>

          {_.get(menus.find(menu => menu.key == currentMenu), 'label', '-')}

          <div style={{display: "inline-block", float: "right", marginRight: 16}}>
            <Popover
              trigger='hover'
              title={user.full_name ? user.full_name : user.username}
              open={openUserMenu}
              onOpenChange={setOpenUserMenu}
              content={
                <Space direction='vertical'>
                  <div style={{ textAlign : 'center', width : '100%', color : colorInfo}}>{user.factory_name}</div>
                  <Button 
                    type='text' 
                    style={{ textAlign: 'left' }} 
                    block 
                    icon={<UnlockOutlined/>} 
                    onClick={() => setOpenChangePassword(true)}>
                    เปลี่ยนรหัสผ่าน
                  </Button>
                  <Button 
                    type='text' 
                    style={{ textAlign: 'left' }} 
                    block 
                    icon={<LogoutOutlined/>} 
                    onClick={() => setOpenLogout(true)}
                    >
                    ออกจากระบบ
                  </Button>
                </Space>
              }>

              <Button 
                shape='round' 
                size='large' 
                style={{ boxShadow: '0 0 0', color: colorPrimary}} 
                onClick={() => setOpenUserMenu(true)}>
                {_.get(user, 'first_name', <UserOutlined/>)}
              </Button>
            </Popover>
          </div>
        </Header>
        <Content
          style={{
            margin: 24,
            minHeight: 280,
            background: colorBgContainer
          }}>
              
          {renderContent()}
        </Content>
      </Layout>

      <LogoutModal 
        open={openLogout}
        onClose={() => setOpenLogout(false)}
        onUpdate={() => setOpenLogout(false)}/>

      <ChangePasswordModal 
        open={openChangePassword}
        onClose={() => setOpenChangePassword(false)}/>
    </Layout>
  )
}